export default {
    computed: {
        showModal() {
            return this.nal.modal ? this.nal.modal.showModal : false;
        },
        backtrace() {
            return this.nal.modal.backtrace;
        },
    },
    methods: {
        setModalIsLoading(isLoading) {
            this.nal.modal.isLoading = isLoading;
        },
    },
};